import { initializeFaro } from '@grafana/faro-web-sdk'
import config from 'config'


if (config.faroUrl && config.faroApiKey) {
  initializeFaro({
    // Mandatory, the URL of the Grafana Cloud collector with embedded application key.
    // Copy from the configuration page of your application in Grafana.
    url: config.faroUrl,
    apiKey: config.faroApiKey,
    // Mandatory, the identification label(s) of your application
    app: {
      name: config.faroAppName,
      version: config.appVersion, // Optional, but recommended
    },
  })
}
